export class InternalServerError extends Error {
  constructor(message) {
    super("InternalServerError", message);
  }
}

export class UnhandledResponseCodeError extends Error {
  constructor(message) {
    super("UnhandledResponseCodeError", message);
  }
}
