import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import { Trans, Translation } from "react-i18next";
import "./otpValidationForm.scss";
import { linkClicked } from "../../api/analytics";
import SubmitButton from "@cx/ui/SubmitButton";
import LinkButton from "../link-button/linkButton";

const onLinkClicked = ({
  element,
  result,
  location = "SMS Password Recovery OTP Authentication Form"
}) => linkClicked(element, result, location);

const onVerifyClicked = result =>
  onLinkClicked({ element: "Verify Button", result });

// TODO: Convert to stateless component
const OtpValidationForm = props => {
  const [passcode, updatePasscode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const onPasscodeInputKeydown = e => {
    if (e.key === "Enter") {
      onClickPasscodeVerifyButton(e);
    }
  };

  const onClickPasscodeVerifyButton = e => {
    e.preventDefault();
    if (!passcode) return;

    setIsVerifying(true);
    props.verifyPasscode(passcode).then(authenticated => {
      if (authenticated) {
        onVerifyClicked(
          "SMS Password Recovery OTP code authentication succeeded"
        );
      } else {
        setIsVerifying(false);
        onVerifyClicked("SMS Password Recovery OTP code authentication failed");
      }
    });
  };

  const onClickResendPassCodeButton = e => {
    e.preventDefault();
    onLinkClicked({
      element: "Resend Code Link",
      result: "Requested Resend SMS Code"
    });
    props.resendPasscode();
  };

  const onClickShowRecoveryOptions = e => {
    onLinkClicked({
      element: "Recovery Options Button",
      result: "Use a different Password Recovery verification method"
    });
    props.showRecoveryOptions(e);
  };

  return (
    <Translation>
      {t => (
        <form id="otp-authentication">
          <div>
            <Trans i18nKey="otpValidationForm.text" />
            <br />
            <br />
            <Trans i18nKey="otpValidationForm.sentText" />
            <br />
            <br />
          </div>
          <TextInput
            autoFocus
            htmlId="otpCode"
            label={t("otpValidationForm.otpChallenge")}
            name="otpCode"
            onChange={e => updatePasscode(e.target.value)}
            value={passcode}
            onKeyDown={onPasscodeInputKeydown}
          />
          <div id="resend-container">
            <LinkButton
              htmlId="resendCodeButton"
              onClick={onClickResendPassCodeButton}
              disabled={isVerifying}
            >
              <Trans i18nKey="otpValidationForm.resendCode" />
            </LinkButton>
          </div>
          <br />
          <div id={"otpValidationButtons"}>
            {props.showRecoveryOptions && (
              <Button
                htmlId="recoveryOptionsButton"
                onClick={onClickShowRecoveryOptions}
                buttonStyle="secondary"
                type="button"
                disabled={isVerifying}
              >
                <Trans i18nKey="otpValidationForm.differentOption" />
              </Button>
            )}
            <SubmitButton
              htmlId="submitOtpCode"
              onClick={onClickPasscodeVerifyButton}
              buttonStyle="primary"
              disabled={!passcode || isVerifying}
              type="button"
              isLoading={isVerifying}
              loadingText={t("accountVerificationForm.buttonLoading")}
            >
              {t("otpValidationForm.submit")}
            </SubmitButton>
          </div>
        </form>
      )}
    </Translation>
  );
};

OtpValidationForm.propTypes = {
  resendPasscode: PropTypes.func.isRequired,
  showRecoveryOptions: PropTypes.func,
  verifyPasscode: PropTypes.func.isRequired
};

export default OtpValidationForm;
