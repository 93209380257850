import React from "react";
import Button from "@cx/ui/Button";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked, report } from "../../api/analytics";
import { fetchFactors } from "../../api/user-factors";

// TODO: Convert to stateless component
// TODO: Discuss moving GA out of individual components to keep them simple
export default class PasswordRecoveryOptionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      factors: [],
      loading: true
    };
  }
  componentDidMount() {
    fetchFactors(
      this.props.apiBaseUrl,
      this.props.username,
      this.props.clientId
    )
      .then(factors => {
        if (this.shouldShortCircuit(factors)) {
          factors[0].indexOf("EMAIL") > -1
            ? this.props.onClickPasswordRecoveryByEmailFromOptionsForm(true)
            : this.props.onClickPasswordRecoveryBySmsFromOptionsForm(true);
        }
        this.setState({ factors });
        if (!factors.length) {
          report(
            "Password Recovery Options Form",
            "No Factors For User",
            "Shown Error Screen"
          );
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        this.setState(state => ({ ...state, error: true }));
      })
      .finally(() => {
        this.setState(state => ({ ...state, loading: false }));
      });
  }

  shouldShortCircuit(factors) {
    return (
      factors.length === 1 ||
      (factors.length === 2 && factors.indexOf("SMS") === -1)
    );
  }

  onClickRecoveryBySmsButton = () => {
    linkClicked(
      "Verify by SMS Code",
      "Initiate SMS Password Recovery",
      "Password Recovery Options Form"
    );
    this.props.onClickPasswordRecoveryBySmsFromOptionsForm();
  };

  onClickRecoveryByEmailButton = () => {
    linkClicked(
      "Verify by Email",
      "Initiate Email Password Recovery",
      "Password Recovery Options Form"
    );
    this.props.onClickPasswordRecoveryByEmailFromOptionsForm();
  };

  render() {
    // render options based on available factor types
    if (this.state.loading || this.shouldShortCircuit(this.state.factors)) {
      return <LoadingIndicator htmlId="loadingIndicator" />;
    }

    if (!this.state.factors.length && !this.state.error) {
      return (
        <div>
          <Translation>
            {t => (
              <form id="passwordRecoveryOptionsForm">
                <div>
                  {t("passwordRecoveryOptionsForm.error")}
                  <br />
                  <br />
                </div>
              </form>
            )}
          </Translation>
        </div>
      );
    } else {
      return (
        <div>
          <Translation>
            {t => (
              <form id="passwordRecoveryOptionsForm">
                <div>
                  <Trans i18nKey="passwordRecoveryOptionsForm.text" />
                  <br />
                  <br />
                </div>
                <Button
                  htmlId="RecoveryByEmailOption"
                  onClick={this.onClickRecoveryByEmailButton}
                  buttonStyle="secondary"
                  block={true}
                  type="button"
                >
                  {t("passwordRecoveryOptionsForm.emailButton")}
                </Button>
                <Button
                  htmlId="RecoveryBySmsOption"
                  onClick={this.onClickRecoveryBySmsButton}
                  buttonStyle="secondary"
                  block={true}
                  type="button"
                >
                  {t("passwordRecoveryOptionsForm.smsButton")}
                </Button>
              </form>
            )}
          </Translation>
        </div>
      );
    }
  }
}

PasswordRecoveryOptionsForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  onClickPasswordRecoveryByEmailFromOptionsForm: PropTypes.func.isRequired,
  onClickPasswordRecoveryBySmsFromOptionsForm: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired
};
