import React from "react";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import "./successRedirect.scss";
import { linkClicked, report } from "../../api/analytics";

// eslint-disable-next-line react/prefer-stateless-function
class SuccessRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delayRender: true
    };
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      report(
        "Loading Explanation Shown",
        "User Signed In",
        `User is waiting for ${this.props.solutionId} to respond`,
        "Signin Success form"
      );
      this.setState({ delayRender: false });
    }, 3000); // Delay for 3 seconds
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onLinkClick = () => {
    linkClicked(
      "Landing Page fallback",
      `Clicked landing page instead of waiting for ${this.props.solutionId}`,
      "Signin Success form"
    );
  };

  render() {
    if (this.state.delayRender) {
      return <LoadingIndicator htmlId={"loading-indicator"} />;
    }

    return (
      <Translation>
        {t => (
          <div className="success-form">
            {
              <>
                <LoadingIndicator htmlId={"loading-indicator"} />
                <div className="message">{t(this.props.message)}</div>
                <div>
                  <Trans
                    i18nKey={this.props.redirectMessage}
                    components={{
                      landingPageLink: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={`${window.location.origin}${
                            this.props.partition.startsWith("PROD")
                              ? ""
                              : "?partition=non-prod"
                          }`}
                          onClick={() => this.onLinkClick()}
                        />
                      )
                    }}
                  />
                </div>
              </>
            }
          </div>
        )}
      </Translation>
    );
  }
}

SuccessRedirect.propTypes = {
  message: PropTypes.string.isRequired,
  partition: PropTypes.string.isRequired,
  redirectMessage: PropTypes.string.isRequired,
  solutionId: PropTypes.string.isRequired
};

export default SuccessRedirect;
