import { InternalServerError, UnhandledResponseCodeError } from "./Errors";

const API_VERSION = "application/vnd.coxauto.v1+json";

export function fetchFactors(baseUrl, username, clientId) {
  const url = new URL(
    baseUrl + `/signin/factors/types?username=${username}&clientId=${clientId}`
  );

  return fetch(url.href, {
    mode: "cors",
    method: "GET",
    headers: {
      Accept: API_VERSION
    }
  }).then(response => {
    switch (response.status) {
      case 200:
        return response.json();
      case 500:
        throw new InternalServerError(
          `Internal Server Error [${response.status}]`
        );
      default:
        throw new UnhandledResponseCodeError(
          `Received ${response.status} response code`
        );
    }
  });
}
