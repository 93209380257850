import React from "react";
import PasswordResetFormErrorArray from "../password-reset-form-error-array/PasswordResetFormErrorArray";
import CardTemplate from "../card/cardTemplate.js";
import PropTypes from "prop-types";
import Popover from "@cx/ui/Popover";
import DisambiguationForm from "../disambiguation-form/disambiguationForm";
import UsernameRecoveryForm from "../username-recovery-form/usernameRecoveryForm";
import UsernameRecoveryStatusForm from "../username-recovery-form/usernameRecoveryStatusForm";
import UsernameRecoveryEmailCaptureForm from "../username-recovery-form/usernameRecoveryEmailCaptureForm";
import PasswordRecoveryOptionsForm from "../password-recovery-form/passwordRecoveryOptionsForm";
import AccountVerificationOptionsForm from "../account-verification-options-form/accountVerificationOptionsForm";
import OtpValidationForm from "../otp-validation-form/otpValidationForm";
import Button from "@cx/ui/Button";
import { getI18n, Trans, Translation } from "react-i18next";
import replace from "react-string-replace";
import { withRouter } from "react-router-dom";
import EmailPasswordSuccessForm from "../email-password-success-form/EmailPasswordSuccessForm";
import DuplicateSessionForm from "../duplicate-session-form/DuplicateSessionForm";
import EulaForm from "../eula/eulaForm";
import AccountVerificationForm from "../account-verification-form/accountVerificationForm";
import FactorEnrollmentOptionsForm from "../factor-enrollment-options-form/factorEnrollmentOptionsForm";
import EnrollVerificationForm from "../enroll-verification/enrollVerificationForm";
import SuccessForm from "../successForm/successForm";
import EnrollFactorForm from "../enroll-verification/enrollInternationalFactorForm";
import ThirdPartyAccessDenied from "../thirdPartyAccessDenied/thirdPartyAccessDenied";
import UserNotLinkedPage from "../UserNotLinkedPage/UserNotLinkedPage";
import UserForm from "../user-form/userForm";
import { externalAppName } from "../env";
import LinkButton from "../link-button/linkButton";
import SuccessRedirect from "../successRedirect/successRedirect";

// eslint-disable-next-line react/prefer-stateless-function
class SignInControl extends React.Component {
  constructor(props) {
    super(props);
    this.userFormAbortButton = this.userFormAbortButton.bind(this);
  }

  userFormAbortButton(abortUrl) {
    if (abortUrl)
      return {
        onClick: () => (window.location.href = this.props.abortUrl),
        text: externalAppName(new URL(abortUrl).hostname)
      };
    else return undefined;
  }

  render() {
    const currentPage = this.props.currentPage || "usernamePage";

    let title = "signInControl.title";
    let usernameText = "signInControl.usernameText";
    let body;
    let showText = true;
    let showSolutionLogo = true;

    const enrollFactor = this.props.enrollFactor;
    switch (currentPage) {
      case "passwordResetPage":
        title = "passwordResetForm.title";
        showText = false;
        body = (
          <PasswordResetFormErrorArray
            username={this.props.username}
            onSubmitPassword={this.props.onSubmitPasswordChange}
            passwordvalidation={this.props.passwordvalidation}
            solutionDisplayName={this.props.solutionDisplayName}
            signinCallback={this.props.onSubmitPassword}
            reasonKey="expired"
            reasonType={"expired"}
            passwordPolicy={this.props.passwordPolicy}
            passwordChangeBackendErrors={this.props.passwordChangeBackendErrors}
          />
        );

        break;

      case "userUnlinkedErrorPage":
        title = "userUnlinkedForm.title";
        showText = false;
        body = (
          <UserNotLinkedPage
            solutionDisplayName={this.props.solutionDisplayName}
            partition={this.props.partition}
          />
        );
        break;

      case "genericMessagePage":
        title = this.props.errorAttrs["I18N_KEY"] + ".title";
        showText = false;

        // We want to be able to pass in formatting like bold, so we need to get the text from
        // i18n then pass it in as an unescaped html element to react to render the formatting.
        // eslint-disable-next-line no-case-declarations
        const bodyText = getI18n().t(
          this.props.errorAttrs["I18N_KEY"] + ".text",
          this.props.errorAttrs,
          { interpolation: { escapeValue: true } } // Escape the inputs (default but being explicit)
        );

        body = (
          <div id={this.props.errorAttrs["I18N_KEY"]}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: bodyText }} />
            <br />
            {this.props.errorAttrs["BUTTON_ACTION"] && (
              <div className="button-group pull-right">
                <br />
                <Button
                  htmlId="genericMessageAction"
                  buttonStyle="primary"
                  onClick={this.props.errorAttrs["BUTTON_ACTION"]}
                >
                  <Trans
                    i18nKey={this.props.errorAttrs["I18N_KEY"] + ".button"}
                    values={this.props.errorAttrs}
                  />
                </Button>
              </div>
            )}
          </div>
        );
        break;

      case "timeoutErrorPage":
        title = "timeoutMessage.timedOutTitle";
        showText = false;
        body = (
          <div id="timeoutErrorPage">
            <Trans
              i18nKey="timeoutMessage.timedOutBody"
              values={{
                solutionGroup: this.props.solutionDisplayName
              }}
            />
            <br /> <br />
            {this.props.solutionLandingUrl && (
              <a href={window.location}>
                <Button htmlId="bridgeLink">
                  <Trans
                    i18nKey="timeoutMessage.reload_button"
                    values={{
                      solutionGroup: this.props.solutionDisplayName
                    }}
                  />
                </Button>
              </a>
            )}
          </div>
        );
        break;

      case "usernameRecoveryPage":
        title = "usernameRecoveryForm.title";
        showText = false;
        body = (
          <UsernameRecoveryForm
            solutionId={this.props.solutionId}
            solutionDisplayName={this.props.solutionDisplayName}
            clientId={this.props.clientId}
            apiBaseUrl={this.props.apiBaseUrl}
            recoverBridgeUsername={this.props.recoverBridgeUsername}
            featureFlags={this.props.featureFlags}
            fetchIdentityProviders={this.props.fetchIdentityProviders}
          />
        );
        break;

      case "usernameRecoveryStatusForm":
        title = "usernameRecoveryForm.title";
        showText = false;
        body = (
          <UsernameRecoveryStatusForm
            recoveryEmail={this.props.recoveryEmail}
            onClickRecoverUsernameStatusBack={
              this.props.onClickRecoverUsernameStatusBack
            }
          />
        );
        break;

      case "usernameRecoveryEmailCaptureForm":
        title = "usernameRecoveryForm.title";
        showText = false;
        body = (
          <UsernameRecoveryEmailCaptureForm
            onClickRecoveryEmailNextButton={
              this.props.onClickRecoveryEmailNextButton
            }
          />
        );
        break;

      case "passwordRecoveryOptionsForm":
        title = "passwordRecoveryOptionsForm.title";
        showText = false;
        body = (
          <PasswordRecoveryOptionsForm
            onClickPasswordRecoveryByEmailFromOptionsForm={
              this.props.onClickPasswordRecoveryByEmailFromOptionsForm
            }
            onClickPasswordRecoveryBySmsFromOptionsForm={
              this.props.onClickPasswordRecoveryBySmsFromOptionsForm
            }
            username={this.props.username}
            clientId={this.props.clientId}
            apiBaseUrl={this.props.apiBaseUrl}
          />
        );
        break;

      case "accountVerificationForm":
        title = "accountVerificationForm.title";
        showText = false;
        body = (
          <AccountVerificationForm
            factors={this.props.verificationFactors}
            returnToChallengeOptions={this.props.returnToChallengeOptions}
            onChallengeFactor={this.props.onChallengeFactor}
            onVerifyFactor={this.props.onVerifyFactor}
          />
        );
        break;

      case "accountVerificationOptionsForm":
        title = "accountVerificationOptionsForm.title";
        showText = false;
        body = (
          <AccountVerificationOptionsForm
            factors={this.props.verificationFactors}
            onChallengeFactor={this.props.onChallengeFactor}
          />
        );
        break;

      case "accountRecoveryEmailSentForm":
        title = "accountRecoveryEmailSentForm.title";
        showText = false;
        body = (
          <EmailPasswordSuccessForm
            showRecoveryOptions={this.props.showRecoveryOptions}
            closePasswordRecovery={this.props.closePasswordRecovery}
          />
        );
        break;

      case "otpValidationForm":
        title = "otpValidationForm.title";
        showText = false;
        body = (
          <OtpValidationForm
            verifyPasscode={this.props.verifyPasscode}
            showRecoveryOptions={this.props.showRecoveryOptions}
            resendPasscode={this.props.resendPasscode}
          />
        );
        break;

      case "accountRecoveryPasswordResetForm":
        title = "accountRecoveryPasswordResetForm.title";
        showText = false;
        body = (
          <PasswordResetFormErrorArray
            username={this.props.username}
            onSubmitPassword={this.props.onAccountRecoveryPasswordChange}
            solutionDisplayName={this.props.solutionDisplayName}
            signinCallback={this.props.onSubmitPassword}
            reasonType={"sms"}
            passwordPolicy={this.props.passwordPolicy}
            passwordChangeBackendErrors={this.props.passwordChangeBackendErrors}
          />
        );
        break;

      case "factorEnrollmentOptionsForm":
        title = `factorEnrollmentOptionsForm.title${
          this.props.mfaEnrollmentForRecovery ? "Recovery" : ""
        }`;
        showText = false;
        showSolutionLogo = false;
        body = (
          <FactorEnrollmentOptionsForm
            onEnrollFactor={this.props.submitFactorEnrollmentOption}
            forRecovery={this.props.mfaEnrollmentForRecovery}
          />
        );
        break;

      case "enrollFactorPage":
        title =
          "enrollFactorForm.title." + enrollFactor.factorType.toLowerCase();
        showText = false;
        showSolutionLogo = false;
        body = (
          <EnrollFactorForm
            factor={enrollFactor}
            onEnrollFactor={this.props.onEnrollFactor}
            onReturnToFactorEnrolmentOptions={
              this.props.returnToFactorEnrolmentOptions
            }
          />
        );
        break;

      case "enrollVerificationForm":
        title = "accountVerificationForm.title";
        showText = false;
        showSolutionLogo = false;
        body = (
          <EnrollVerificationForm
            factor={enrollFactor}
            onChallengeFactor={this.props.onEnrollFactor}
            onVerifyFactor={this.props.onVerifyEnrollFactor}
            returnToEnrollOptions={this.props.returnToFactorEnrolmentOptions}
            successCallback={this.props.onEnrollSuccess}
          />
        );
        break;

      case "factorEnrollSuccessForm":
        title = null;
        showText = false;
        showSolutionLogo = false;
        body = (
          <SuccessForm
            message={`enrollFactorForm.success.${enrollFactor.factorType.toLowerCase()}`}
            onClick={this.props.onContinueSignin}
            solutionDisplayName={this.props.solutionDisplayName}
          />
        );
        break;

      case "duplicateSessionDetected":
        title = "duplicateSession.title";
        showText = false;
        body = (
          <DuplicateSessionForm
            retryFunction={this.props.errorAttrs["retryCallback"]}
            sessionBridgeUsername={this.props.errorAttrs["bridgeUsername"]}
            newUsername={this.props.username}
            bridgeEnv={this.props.errorAttrs["bridgeEnv"]}
          />
        );
        break;

      case "eulaPage":
        title = "eulaForm.title";
        showText = false;
        showSolutionLogo = false;
        body = <EulaForm onAcceptEula={this.props.onAcceptEula} />;
        break;

      case "thirdPartyAccessDenied":
        title = "thirdPartyAccessDenied.title";
        showText = false;
        showSolutionLogo = false;
        body = (
          <ThirdPartyAccessDenied
            apiBaseUrl={this.props.apiBaseUrl}
            clientId={this.props.clientId}
            partition={this.props.partition}
          />
        );
        break;

      case "successRedirectCard":
        title = null;
        showText = false;
        showSolutionLogo = false;
        body = (
          <SuccessRedirect
            message={"successRedirect.message"}
            redirectMessage={"successRedirect.redirectMessage"}
            partition={this.props.partition}
            solutionId={this.props.solutionId}
          />
        );
        break;

      case "disambiguationPage":
      case "usernamePage":
      case "passwordPage":
      default: {
        // For IE and other password managers, we can not swap out the screen between the user, password, and disambiguation screens.
        // so we are rendering the user form at all times, the form will hide the unnecessary components using visual tricks with the
        // stylesheets instead of removing from dom or display none as it seems to break many password managers.
        let disambiguation;
        if (currentPage === "disambiguationPage") {
          disambiguation = (
            <DisambiguationForm
              username={this.props.username}
              solutionList={this.props.solutionList}
              onClickUsernameFromDisambiguationPage={
                this.props.onClickUsernameFromPasswordPage
              }
              onSubmitSolutionName={this.props.onClickSolutionName}
            />
          );
        }

        if (this.props.currentFlow === "solutionIdentity") {
          title = "solutionIdentityFlow.title";
          usernameText = "solutionIdentityFlow.usernameText";
        }

        body = (
          <div>
            {disambiguation}
            <UserForm
              username={this.props.username}
              onSubmitUsername={this.props.onSubmitUsername}
              onForgotUsernameClick={this.props.onForgotUsernameClick}
              onForgotPasswordClick={this.props.onForgotPasswordClick}
              solution={this.props.solutionDisplayName}
              authProviderDisplayName={this.props.authProviderDisplayName}
              onSubmitPassword={this.props.onSubmitPassword}
              onClickUsernameFromPasswordPage={
                this.props.onClickUsernameFromPasswordPage
              }
              page={currentPage}
              showForgotUsername={this.props.showForgotUsername}
              showForgotPassword={this.props.showForgotPassword}
              abortButton={this.userFormAbortButton(this.props.abortUrl)}
              currentFlow={this.props.currentFlow}
              featureFlags={this.props.featureFlags}
            />
          </div>
        );
      }
    }

    let usernameTextComponent = (
      <Translation>
        {t => (
          <span className="username-text">
            {replace(
              t(usernameText, {
                solutionDisplayName:
                  this.props.solutionDisplayName === undefined
                    ? "solution"
                    : this.props.solutionDisplayName
              }),
              "{BRIDGEID}",
              () => (
                <Popover
                  htmlId="popoverLink"
                  className="popover-item"
                  popoverContent={
                    <div className="popover-text">
                      <Trans
                        i18nKey={
                          this.props.bridgeNative
                            ? "signInControl.popover_bridgeNative"
                            : "signInControl.popover"
                        }
                        values={{
                          solutionDisplayName: this.props.solutionDisplayName
                        }}
                      />
                    </div>
                  }
                  trigger={["click", "outsideClick"]}
                  position="right"
                >
                  <LinkButton htmlId="bridgeLink">Bridge ID</LinkButton>
                </Popover>
              )
            )}
            <br />
            <br />
          </span>
        )}
      </Translation>
    );

    // Note that it makes no sense for the second line to say “Use your Dealertrack F&I username and password or your Bridge ID account” because they are one and the same.
    if (
      this.props.solutionDisplayName !== undefined &&
      this.props.currentFlow !== "solutionIdentity"
    ) {
      if (
        this.props.solutionDisplayName.toLowerCase().indexOf("dealertrack") !==
          -1 ||
        this.props.bridgeNative
      ) {
        usernameTextComponent = (
          <Translation>
            {t => (
              <span className="username-text">
                {replace(
                  t("signInControl.usernameText_bridgeOnly"),
                  "{BRIDGEID}",
                  () => (
                    <Popover
                      htmlId="popoverLink"
                      className="popover-item"
                      popoverContent={
                        <div className="popover-text">
                          <Trans
                            i18nKey={
                              this.props.bridgeNative
                                ? "signInControl.popover_bridgeNative"
                                : "signInControl.popover"
                            }
                            values={{
                              solutionDisplayName: this.props
                                .solutionDisplayName
                            }}
                          />
                        </div>
                      }
                      trigger={["click", "outsideClick"]}
                      position="right"
                    >
                      <LinkButton htmlId="bridgeLink">Bridge ID</LinkButton>
                    </Popover>
                  )
                )}
                <br />
                <br />
              </span>
            )}
          </Translation>
        );
      }
    }

    return (
      <div className="cardContainer" id="signInControlContainer">
        <CardTemplate
          title={
            title ? (
              <Trans
                i18nKey={title}
                values={{
                  solutionDisplayName:
                    this.props.solutionDisplayName !== undefined
                      ? this.props.solutionDisplayName
                          .toLowerCase()
                          .indexOf("dealertrack") !== -1
                        ? "Dealertrack"
                        : "" + this.props.solutionDisplayName
                      : "undefined",
                  username: this.props.username || "undefined"
                }}
              />
            ) : (
              ""
            )
          }
          body={
            <div>
              {showText && usernameTextComponent}
              {body}
            </div>
          }
          contactUsUrl={
            this.props.currentFlow === "solutionIdentity"
              ? undefined
              : this.props.contactUsUrl
          }
          logo={showSolutionLogo && this.props.solutionLogoURI}
          logoAlt={showSolutionLogo && this.props.solutionDisplayName + " logo"}
        />
      </div>
    );
  }
}

SignInControl.propTypes = {
  abortUrl: PropTypes.string,
  apiBaseUrl: PropTypes.string.isRequired,
  authProviderDisplayName: PropTypes.string,
  availableSolutions: PropTypes.array,
  bridgeNative: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  closePasswordRecovery: PropTypes.func.isRequired,
  contactUsUrl: PropTypes.string,
  currentFlow: PropTypes.string,
  currentPage: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  enrollFactor: PropTypes.object,
  errorAttrs: PropTypes.object,
  featureFlags: PropTypes.object.isRequired,
  fetchIdentityProviders: PropTypes.func.isRequired,
  mfaEnrollmentForRecovery: PropTypes.bool,
  onAcceptEula: PropTypes.func.isRequired,
  onAccountRecoveryPasswordChange: PropTypes.func.isRequired,
  onChallengeFactor: PropTypes.func.isRequired,
  onClickPasswordRecoveryByEmailFromOptionsForm: PropTypes.func.isRequired,
  onClickPasswordRecoveryBySmsFromOptionsForm: PropTypes.func.isRequired,
  onClickRecoverUsernameStatusBack: PropTypes.func.isRequired,
  onClickRecoveryEmailNextButton: PropTypes.func.isRequired,
  onClickSolutionName: PropTypes.func.isRequired,
  onClickUsernameFromPasswordPage: PropTypes.func.isRequired,
  onContinueSignin: PropTypes.func.isRequired,
  onEnrollFactor: PropTypes.func.isRequired,
  onEnrollSuccess: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onForgotUsernameClick: PropTypes.func.isRequired,
  onSubmitPassword: PropTypes.func.isRequired,
  onSubmitPasswordChange: PropTypes.func.isRequired,
  onSubmitUsername: PropTypes.func.isRequired,
  onVerifyEnrollFactor: PropTypes.func.isRequired,
  onVerifyFactor: PropTypes.func.isRequired,
  partition: PropTypes.string,
  passwordChangeBackendErrors: PropTypes.array,
  passwordPolicy: PropTypes.string,
  passwordvalidation: PropTypes.string,
  recoverBridgeUsername: PropTypes.func.isRequired,
  recoveryEmail: PropTypes.string,
  resendPasscode: PropTypes.func.isRequired,
  returnToChallengeOptions: PropTypes.func.isRequired,
  returnToFactorEnrolmentOptions: PropTypes.func.isRequired,
  showForgotPassword: PropTypes.bool.isRequired,
  showForgotUsername: PropTypes.bool.isRequired,
  showRecoveryOptions: PropTypes.func,
  solutionDisplayName: PropTypes.string.isRequired,
  solutionId: PropTypes.string,
  solutionLandingUrl: PropTypes.string,
  solutionList: PropTypes.array,
  solutionLogoURI: PropTypes.string,
  submitFactorEnrollmentOption: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  verificationFactors: PropTypes.array,
  verifyPasscode: PropTypes.func.isRequired
};

export default withRouter(SignInControl);
