import React from "react";
import PropTypes from "prop-types";

import { Trans } from "react-i18next";
import Button from "@cx/ui/Button";
import SubmitButton from "@cx/ui/SubmitButton";
import { linkClicked } from "../../api/analytics";

import "./EmailPasswordSuccessForm.scss";

const onClickShowRecoveryOptions = ({ props, e }) => {
  linkClicked(
    "Recovery Options Button",
    "Use a different Password Recovery verification method",
    "Email Password Recovery"
  );
  props.showRecoveryOptions(e);
};

const onClickClose = ({ props, e }) => {
  linkClicked(
    "Close Button",
    "Close Password Recovery",
    "Email Password Recovery"
  );
  props.closePasswordRecovery(e);
};

const EmailPasswordSuccessForm = props => (
  <div>
    <form id="emailPasswordRecoveryConfirmation">
      <div>
        <Trans i18nKey="accountRecoveryEmailSentForm.titleText" />
        {/* why are we using linebreaks instead of <p> (paragraphs) with CSS? */}
        <br />
        <br />
        <strong>
          <Trans i18nKey="accountRecoveryEmailSentForm.resultTitle" />
        </strong>
        <br />
        <br />
        <Trans i18nKey="accountRecoveryEmailSentForm.resultText" />
        <br />
        <br />
      </div>
      <div id="emailPasswordSuccessButtons">
        {props.showRecoveryOptions && (
          <Button
            htmlId="recoveryOptionsButton"
            onClick={e => onClickShowRecoveryOptions({ props, e })}
            buttonStyle="secondary"
            type="button"
          >
            <Trans i18nKey="otpValidationForm.differentOption" />
          </Button>
        )}
        <SubmitButton
          htmlId="closeButton"
          onClick={e => onClickClose({ props, e })}
          type="button"
        >
          <Trans i18nKey="accountRecoveryEmailSentForm.closeButton" />
        </SubmitButton>
      </div>
    </form>
  </div>
);

EmailPasswordSuccessForm.propTypes = {
  closePasswordRecovery: PropTypes.func.isRequired,
  showRecoveryOptions: PropTypes.func
};

export default EmailPasswordSuccessForm;
